import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import productCurvyLines from "../assets/productCurvyLines.png";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CellTowerIcon from "@mui/icons-material/CellTower";

const item: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
  border: "rgb(255, 51, 102) 5px solid",
  borderRadius: "20px",
  padding: "20px",
  minHeight: "300px",
};

interface Props {
  goToPackages: (_event: React.SyntheticEvent, newValue: number) => void;
}

function ProductValues(props: Props) {
  return (
    <Box
      component="section"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "secondary.light" }}
    >
      <Container sx={{ mt: 15, mb: 30, display: "flex", position: "relative" }}>
        <Box
          component="img"
          src={productCurvyLines}
          alt="curvy lines"
          sx={{ pointerEvents: "none", position: "absolute", top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <div
              style={{ cursor: "pointer" }}
              onClick={(event) => props.goToPackages(event, 1)}
            >
              <Box sx={item}>
                <MapsHomeWorkIcon sx={{ height: 50, width: 50, zIndex: 1 }} />
                <Typography variant="h6" sx={{ my: 5 }}>
                  Residential Listings
                </Typography>
                <Typography variant="h5">
                  {
                    "From small houses to mansions, and all the aminities they have."
                  }
                </Typography>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <ApartmentIcon sx={{ height: 50, width: 50, zIndex: 1 }} />
              <Typography variant="h6" sx={{ my: 5 }}>
                Commercial Needs
              </Typography>
              <Typography variant="h5">
                {
                  "From ice cream shops to golf clubs and everything in between."
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <CellTowerIcon sx={{ height: 50, width: 50, zIndex: 1 }} />
              <Typography variant="h6" sx={{ my: 5 }}>
                Inspections and Mappings
              </Typography>
              <Box>
                <div
                  style={{
                    position: "absolute",
                    transform: `rotate(-40deg)`,
                    fontSize: 40,
                    color: "#ff3366",
                    paddingBottom: 100,
                    paddingLeft: 100,
                    userSelect: "none",
                  }}
                >
                  Near Future
                </div>
                <Typography variant="h5">
                  {
                    "Inspecting constructions or any structure, along with mapping services."
                  }
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
