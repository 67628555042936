import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import HomeImage from "../assets/home.jpg";
import { Button, Typography } from "@mui/material";
import { useRef, useState, useLayoutEffect } from "react";

const HomeLayoutRoot = styled("section")(({ theme }) => ({
  color: theme.palette.common.white,
  position: "relative",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    height: "80vh",
    minHeight: 500,
    maxHeight: 1800,
  },
}));

const Background = styled(Box)({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  zIndex: -2,
});

interface Props {
  requestQuote: (_event: React.SyntheticEvent, newValue: number) => void;
}

export default function Home(props: Props) {
  const elemRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1.25);
  const [preScroll, setPreScroll] = useState<number>(0);

  useLayoutEffect(() => {
    const botPos = (element: HTMLDivElement | null) =>
      element?.getBoundingClientRect().bottom;
    const onScroll = () => {
      const divBotPos = botPos(elemRef.current);
      const scrollPos = preScroll > window.scrollY;
      setPreScroll(window.scrollY);
      if (scrollPos && divBotPos && divBotPos > window.innerHeight) {
        setScale(1);
        return;
      }
      if (divBotPos && divBotPos < window.innerHeight) {
        setScale(1.5);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  });

  return (
    <div
      ref={elemRef}
      style={{
        width: "100%",
        height: "80vh",
        minHeight: 500,
        maxHeight: 1800,
        overflow: "hidden",
      }}
    >
      <HomeLayoutRoot>
        <Container
          sx={{
            mt: 3,
            mb: 14,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography color="white" align="center" variant="h2">
            Take Your Business To A Higher Level
          </Typography>

          <Typography
            color="inherit"
            align="center"
            variant="h5"
            sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
          >
            Website is Under Construction. More Awesome Content Coming Soon!
          </Typography>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            component="a"
            sx={{ minWidth: 200 }}
            onClick={(event) => props.requestQuote(event, 2)}
          >
            Request Quote
          </Button>
          <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
            Discover the possibilities
          </Typography>
          <Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: "common.black",
              opacity: 0.5,
              zIndex: -1,
            }}
          />
          <Background
            sx={{
              backgroundImage: `url(${HomeImage})`,
              backgroundColor: "#7fc7d9",
              backgroundPosition: "center",
              transition: "transform 1000ms ease-in-out",
              transform: `scale(${scale})`,
            }}
          />
          <ArrowDownwardIcon />
        </Container>
      </HomeLayoutRoot>
    </div>
  );
}
