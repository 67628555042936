import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

function Copyright() {
  return (
    <div>
      {"© Sky's Eyes LLC "}
      {new Date().getFullYear()}
    </div>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "black",
  backgroundColor: "warning.main",
  mr: 1,
  "&:hover": {
    bgcolor: "warning.dark",
  },
};

export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{
        display: "flex",
        bgcolor: "secondary.light",
        width: "100%",
      }}
    >
      <Container sx={{ my: 8, display: "flex" }}>
        <Grid
          container
          direction="column"
          justifyContent="flex-end"
          spacing={2}
        >
          <Grid item sx={{ display: "flex" }}>
            <Box
              component="a"
              href="https://www.alpha.facebook.com/profile.php?id=100087253565505"
              target="_blank"
              sx={iconStyle}
            >
              <FacebookIcon />
            </Box>
            <Box
              component="a"
              href="https://www.instagram.com/skys_eyes_llc/"
              target="_blank"
              sx={iconStyle}
            >
              <InstagramIcon />
            </Box>
            <Box
              component="a"
              href="https://www.youtube.com/channel/UCGP4VsRZ2dQUjWR-xSZBQYA/featured"
              target="_blank"
              sx={iconStyle}
            >
              <YouTubeIcon />
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="column" justifyContent={"flex-end"}>
          <Copyright />
        </Grid>
      </Container>
    </Typography>
  );
}
