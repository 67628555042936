import { Toolbar, Link, Box, Tab, Tabs, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import React, { useState } from "react";
import Home from "./components/Home";
import ProductValue from "./components/ProductValue";
import RequestQuote from "./components/RequestQuote";
import Packages from "./components/Packages";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SkysEyesAppBar() {
  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <AppBar elevation={0} position="sticky">
        <Toolbar>
          <Link
            variant="h6"
            underline="none"
            color="inherit"
            href="/"
            sx={{ fontSize: 24, paddingLeft: 1, paddingRight: 5 }}
          >
            Sky's Eyes
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              aria-label="full width tabs example"
            >
              <Tab label="Services" {...a11yProps(0)} />
              <Tab label="Packages" {...a11yProps(1)} />
              <Tab label="Contact" {...a11yProps(2)} />
            </Tabs>
          </Box>
          {/* <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <Link
              color="inherit"
              variant="h6"
              underline="none"
              href="/request-quote/"
              sx={rightLink}
            >
              {"Request Quote"}
            </Link>
          </Box> */}
        </Toolbar>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Home requestQuote={handleChange} />
        {/* <Typography>
          {
            "Specialized in drone photography and videography, run by drone enthusiasts that go extra mile to bring a new perspective to your listing and enjoy every part of it."
          }
        </Typography> */}
        <ProductValue goToPackages={handleChange} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Packages />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <RequestQuote />
      </TabPanel>
    </React.Fragment>
  );
}

export default SkysEyesAppBar;
