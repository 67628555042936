import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

export default function Packages() {
  return (
    <Grid container spacing={5} sx={{ padding: 5, bgcolor: "secondary.light" }}>
      <Grid item xs={12} sm={12}>
        <Box
          sx={{
            bgcolor: "secondary.main",
            p: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: "white", display: "flex", justifyContent: "center" }}
          >
            Residential Listing
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          sx={{
            border: "rgb(255, 51, 102) 5px solid",
            borderRadius: "20px",
            p: 2,
            minHeight: 400,
          }}
        >
          <Typography
            variant="h6"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            Standard - $250
          </Typography>
          <Box sx={{ width: "100%" }}>
            <div style={{ display: "inline-flex" }}>
              <Typography>
                <span style={{ fontSize: "20px", padding: "5px" }}>&bull;</span>
                15 high quality edited photos, including:
              </Typography>
            </div>
            <div style={{ display: "inline-flex" }}>
              <Typography>
                <span style={{ paddingRight: "20px" }}></span>- Front and each
                corner at 10 - 20 feet high
              </Typography>
            </div>
            <div style={{ display: "inline-flex" }}>
              <Typography>
                <span style={{ paddingRight: "20px" }}></span>- Front and each
                corner at 100 feet high
              </Typography>
            </div>
            <div style={{ display: "inline-flex" }}>
              <Typography>
                <span style={{ paddingRight: "20px" }}></span>- Bird eye view of
                the lot
              </Typography>
            </div>
            <div style={{ display: "inline-flex" }}>
              <Typography>
                <span style={{ paddingRight: "20px" }}></span>
                {"- Focus points(swimming pool, garden, gazebo, etc.)"}
              </Typography>
            </div>
          </Box>
          <br />
          <Typography
            variant="h6"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            Standard Plus - $400
          </Typography>
          <Box sx={{ width: "100%" }}>
            <div style={{ display: "inline-flex" }}>
              <Typography>
                <span style={{ fontSize: "20px", padding: "5px" }}>&bull;</span>
                Beautiful pond, playground, neighborhood swimming pool? Get them
                all covered under this "Plus" package. This will compliment your
                listing and bring customer's attention to the max.
              </Typography>
            </div>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          sx={{
            border: "rgb(255, 51, 102) 5px solid",
            borderRadius: "20px",
            p: 2,
            minHeight: 400,
          }}
        >
          <Typography
            variant="h6"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            Elite - $350
          </Typography>
          <Box sx={{ width: "100%" }}>
            <div style={{ display: "inline-flex" }}>
              <Typography>
                <span style={{ fontSize: "20px", padding: "5px" }}>&bull;</span>
                Everything in the Standard package
              </Typography>
            </div>
            <div style={{ display: "inline-flex" }}>
              <Typography>
                <span style={{ fontSize: "20px", padding: "5px" }}>&bull;</span>
                10 vertical shots social media ready. Specially designed for
                reels, stories, shorts and TikTok.
              </Typography>
            </div>
          </Box>
          <br />
          <Typography
            variant="h6"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            Elite Plus - $500
          </Typography>
          <Box sx={{ width: "100%" }}>
            <div style={{ display: "inline-flex" }}>
              <Typography>
                <span style={{ fontSize: "20px", padding: "5px" }}>&bull;</span>
                Everything in the Standard Plus package
              </Typography>
            </div>
            <div style={{ display: "inline-flex" }}>
              <Typography>
                <span style={{ fontSize: "20px", padding: "5px" }}>&bull;</span>
                5 vertical shots for neighborhood pond, playground or swimming
                pool. Social media ready. Specially designed for reels, stories,
                shorts and TikTok.
              </Typography>
            </div>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          sx={{
            border: "rgb(255, 51, 102) 5px solid",
            borderRadius: "20px",
            p: 2,
            minHeight: 400,
          }}
        >
          <Typography
            variant="h6"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            Ultimate - $450
          </Typography>
          <Box sx={{ width: "100%" }}>
            <div style={{ display: "inline-flex" }}>
              <Typography>
                <span style={{ fontSize: "20px", padding: "5px" }}>&bull;</span>
                Everything in the Elite package
              </Typography>
            </div>
            <div style={{ display: "inline-flex" }}>
              <Typography>
                <span style={{ fontSize: "20px", padding: "5px" }}>&bull;</span>
                1 360 shot which shows the house and the neighborhood from a
                perspective that's never been seen before
              </Typography>
            </div>
          </Box>
          <br />
          <Typography
            variant="h6"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            Ultimate Plus - $550
          </Typography>
          <Box sx={{ width: "100%" }}>
            <div style={{ display: "inline-flex" }}>
              <Typography>
                <span style={{ fontSize: "20px", padding: "5px" }}>&bull;</span>
                Everything in the Elite Plus package
              </Typography>
            </div>
            <div style={{ display: "inline-flex" }}>
              <Typography>
                <span style={{ fontSize: "20px", padding: "5px" }}>&bull;</span>
                1 360 shot of an area of interest in the neighborhood.
              </Typography>
            </div>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={2}></Grid>
      <Grid item xs={12} sm={4}>
        <Box
          sx={{
            border: "rgb(255, 51, 102) 5px solid",
            borderRadius: "20px",
            p: 2,
            minHeight: 300,
          }}
        >
          <Typography
            variant="h6"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            Standalone Social Media - $175
          </Typography>
          <Box sx={{ width: "100%" }}>
            <div style={{ display: "inline-flex" }}>
              <Typography>
                <span style={{ fontSize: "20px", padding: "5px" }}>&bull;</span>
                Get 10 vertical shots. Specially designed for reels, stories,
                shorts and TikTok. These shots fit every social media platform
                perfectly. These are NOT cropped photos, rather shot through
                camera lenses specialized for social media.
              </Typography>
            </div>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          sx={{
            border: "rgb(255, 51, 102) 5px solid",
            borderRadius: "20px",
            p: 2,
            minHeight: 300,
          }}
        >
          <Typography
            variant="h6"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            Standalone 360 Panorama - $140
          </Typography>
          <Box sx={{ width: "100%" }}>
            <div style={{ display: "inline-flex" }}>
              <Typography>
                <span style={{ fontSize: "20px", padding: "5px" }}>&bull;</span>
                View the property and the landscape around it from a completely
                different perspective. This will bring the utmost attention to
                your listing.
              </Typography>
            </div>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={2}></Grid>
    </Grid>
  );
}
