import React from "react";
import withRoot from "./withRoot";
import SkysEyesAppBar from "./SkysEyesAppBar";
import AppFooter from "./components/AppFooter";

function App() {
  return (
    <React.Fragment>
      <SkysEyesAppBar />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(App);
