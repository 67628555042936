import { InputLabel, Select, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import withRoot from "../withRoot";
import Paper from "./Paper";
import emailjs from "@emailjs/browser";

function RequestQuote() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [property, setProperty] = useState("");
  const [message, setMessage] = useState("");
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (name.length > 0 && email.length > 0 && message.length > 0) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [name, email, message]);

  const onSubmit = () => {
    emailjs
      .send(
        "service_44fzt4y",
        "template_hmp0qfb",
        {
          name: name,
          email: email,
          phone: phone,
          property: property,
          message: message,
        },
        "ikqNCOZqmDLOK13S2"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <Box
        component="section"
        sx={{ display: "flex", overflow: "hidden", bgcolor: "secondary.light" }}
      >
        <Container maxWidth="md">
          <Box sx={{ mt: 7, mb: 12 }}>
            <Paper
              background="light"
              sx={{ py: { xs: 4, md: 8 }, px: { xs: 3, md: 6 } }}
            >
              <Box sx={{ paddingBottom: 3 }}>
                <Typography variant="h3" gutterBottom align="center">
                  Request A Quote
                </Typography>
                <span
                  style={{
                    height: "4px",
                    width: "55px",
                    display: "block",
                    margin: "8px auto 0",
                    backgroundColor: "#ff3366",
                  }}
                ></span>
              </Box>
              <Box sx={{ minWidth: 120, paddingBottom: 3 }}>
                <FormControl fullWidth>
                  <TextField
                    id="name"
                    label="Name"
                    required
                    variant="outlined"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    error={name.length === 0}
                    helperText={name.length === 0 ? "Required." : ""}
                  />
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 120, paddingBottom: 3 }}>
                <FormControl fullWidth>
                  <TextField
                    id="email"
                    label="Email"
                    required
                    variant="outlined"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    error={email.length === 0}
                    helperText={email.length === 0 ? "Required." : ""}
                  />
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 120, paddingBottom: 3 }}>
                <FormControl fullWidth>
                  <TextField
                    id="phone"
                    label="Phone Number"
                    variant="outlined"
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Property Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={property}
                    label="Property Type"
                    onChange={(event) => setProperty(event.target.value)}
                  >
                    <MenuItem value={"Residential"}>Residential</MenuItem>
                    <MenuItem value="Commercial">Commercial</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
                <Box sx={{ paddingTop: 3 }}>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      label="Message"
                      value={message}
                      onChange={(event) => setMessage(event.target.value)}
                      error={message.length === 0}
                      helperText={message.length === 0 ? "Required" : ""}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Button
                sx={{ mt: 3, mb: 2 }}
                size="large"
                color="secondary"
                variant="contained"
                fullWidth
                disabled={!valid}
                onClick={onSubmit}
              >
                {"Send"}
              </Button>
            </Paper>
          </Box>
        </Container>
      </Box>
    </div>
  );
}

export default withRoot(RequestQuote);
